import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Image,
} from "@chakra-ui/react";
import { useState } from "react";
import classes from "./Questions.module.css";

const FAQS = [
  {
    id: "f1",
    question: "How does pocket food work?",
    answer:
      "Pocket Food simply sorts out all your food needs with a host of specialized features. Create your profile, choose a flexible subscription, and get fresh, customized meals delivered. Tailor plans, monitor expenses, and enjoy convenient, healthy eating tailored to your lifestyle. No hassle, just delicious and nourishing meals crafted for you.",
  },
  {
    id: "f2",
    question: "Is Pocketfood suitable for specific diets or health conditions?",
    answer:
      "Certainly! Our personalized nutrition AI model is crafted to be highly flexible and inclusive, making it suitable for a diverse range of dietary preferences and health goals.",
  },
  {
    id: "f3",
    question: "Can I order one-off meals without a subscription ?",
    answer:
      "Absolutely! You can order individual meals without any subscription commitment. Enjoy the flexibility of getting your preferred meal whenever you desire. Your Meal, Your Way, Anytime!",
  },
  {
    id: "f4",
    question: "What if I need to change my delivery address or date? ",
    answer:
      "If you ever need to adjust your delivery details, not a problem at all. You can easily modify your address book or date directly within the app, providing you with the convenience and control to suit your changing needs.",
  },
  {
    id: "f5",
    question: "Can I specify food preferences and allergies in my profile?",
    answer:
      "You can personalize your Pocket Food experience by specifying your food preferences and allergies in your profile. This ensures that the AI-generated meal plans align with your tastes while excluding any allergens, creating a safe and tailored meal plan for you.",
  },
  {
    id: "f6",
    question: "Can I pause my subscription or skip meals for a certain period?",
    answer:
      "Of course! Pocket Food offers you the flexibility to manage your subscription with ease. You can pause meals, swap them, skip meals, and modify your plans according to your preferences. It's all about providing you with the flexibility to tailor your meals to fit your lifestyle.",
  },
  {
    id: "f7",
    question: "Is pocketfood available in my area?",
    answer:
      "We currently serve Lagos mainland and island, a full list of our locations can be found here. We are primed to move into major cities in Nigeria so watch out for us",
  },
];

const Question = () => {
  const [show, setShow] = useState(false);
  //   const router = useLocation();
  //   let getStartedPath = false;

  //   if (router.pathname === "/get-started") {
  //     getStartedPath = true;
  //   }

  const showHandlerFunction = () => {
    setShow((prev) => !prev);
  };
  return (
    <div className={`${classes.container} ${classes.check}`}>
      <div className={classes.image}>
        <Image
          src="/female.png"
          alt="female"
          width="500"
          height="650"
          priority={true}
        />
      </div>

      <div
        className={classes.questions}
        id="faqs"
        style={{ backgroundColor: "#F9F4FF" }}
      >
        <h2>Frequently Asked Questions</h2>
        <div className={classes.faqsContainer}>
          <Accordion allowToggle>
            {FAQS.map((faq, index) => {
              return (
                <AccordionItem key={index} border={"none"}>
                  {({ isExpanded }) => (
                    <>
                      <h2>
                        <AccordionButton>
                          <Box
                            as="span"
                            flex="1"
                            textAlign="left"
                            lineHeight={"1.8rem"}
                          >
                            {faq.question}
                          </Box>
                          {isExpanded ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="21"
                              viewBox="0 0 20 21"
                              fill="none"
                            >
                              <path
                                d="M5.83301 8.7417L9.99967 12.9084L14.1663 8.7417H5.83301Z"
                                fill="#0C0900"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="21"
                              viewBox="0 0 20 21"
                              fill="none"
                            >
                              <path
                                d="M8.33301 14.575L12.4997 10.4083L8.33301 6.24162L8.33301 14.575Z"
                                fill="#0C0900"
                              />
                            </svg>
                          )}
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>{faq.answer}</AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              );
            })}
          </Accordion>

          {/* <AccordionItem border="none">
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Section 1 title
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </AccordionPanel>
          </AccordionItem> */}

          {/* {FAQS.map((data) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1.5rem",
                marginTop: "1.5rem",
              }}
              key={data.id}
            >
              <details className={classes.details}>
                <summary className={classes.faq}>{data.question}</summary>
                <p className={classes.answer}>{data.answer}</p>
              </details>
            </div>
          ))} */}
        </div>
      </div>
    </div>
  );
};

export default Question;
