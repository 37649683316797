import { Image } from "@chakra-ui/react";
import classes from "./GetStarted.module.css";
// import Image from "next/image";

const GetStarted = () => {
  return (
    <div className={classes.imageContainer}>
      <div className={classes.inner__container}>
        <div className={classes.fruit1}>
          <img
            src="/subscriptionFow/cucumber.png"
            alt=""
            width="289"
            height="289"
          />
        </div>
        <div className={classes.fruit2}>
          <Image
            src="/subscriptionFow/tangarine.png"
            alt=""
            width="289"
            height="289"
          />
        </div>
        <div className={classes.subscriptionFlow}>
          <Image
            src="/subscriptionFow/homePageSubscriptionFlow.png"
            alt="Subscription flow"
            width="1000"
            height="800"
            priority={true}
          />
        </div>
      </div>
    </div>
  );
};

export default GetStarted;
