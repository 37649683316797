import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import App from './App';
import './components/Constant/colors.css'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const theme = extendTheme({
  fonts: {
    body: `'Galano'`,
  },
});
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>,
);
