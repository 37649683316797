import React, { ReactNode, useState } from "react";
import classes from "./Header.module.css";
import { AnimatePresence, motion } from "framer-motion";
import { Button, Image, Link } from "@chakra-ui/react";
import BaseDirectories from "../base directories/BaseDirectories";

const Navbar = () => {
  // const [toggle, setToggleHandler] = useState(false);

  // const showMenuHandler = () => {
  //   setToggleHandler((prev) => !prev);
  // };

  return (
    <>
      <div className={classes.headerContainer}>
        <header className={classes.header}>
          <Link href="https://pocketfood.io/">
            <Image
              src={"/images/logos/Pocketfood.svg"}
              alt="PocketFood logo"
              w={'121px'}
              // width="121"
              // height="48"
              // priority={true}
            />
          </Link>
          <nav className={classes.navigation}>
            <ul>
              <li>
                <Link href="https://pocketfood.io/about" fontWeight={500}>About</Link>
              </li>
              <li>
                <Link href="https://pocketfood.io/#faqs" fontWeight={500}>FAQs</Link>
              </li>
              <li>
                <Link href={"https://pocketfood.io/"}>
                <Button
                    borderRadius={"36px"}
                    // w="120px"
                    bgColor={"#F18313"}
                    color="#fff"
                    p='25px 32px'
                    fontWeight={400}
                  >
                    Get started
                  </Button>
                </Link>
              </li>
            </ul>
          </nav>

          {/* {!toggle && <div className={classes.menu} onClick={showMenuHandler}>
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="50" viewBox="0 0 24 24" fill="none">
            <g clipPath="url(#clip0_7679_1083)">
            <path d="M3 18H21V16H3V18ZM3 13H21V11H3V13ZM3 6V8H21V6H3Z" fill="#323232"/>
            </g>
            <defs>
            <clipPath id="clip0_7679_1083">
            <rect width="40" height="40" fill="white"/>
            </clipPath>
            </defs>
          </svg>
        </div>} */}
        </header>
      </div>
    </>
  );
};

export default Navbar;
