const BaseDirectories = {
  /**
   ** The base URL of the application
   */
  BASE_URL: process.env.REACT_APP_BASEURL,

  /**
   * The base URL of the Server API
   */
  API_BASE_URL: process.env.REACT_APP_API_BASEURL,

  GOOGLE_MAP_API_KEY: process.env.REACT_APP_GOOGLEAPI_KEY,

  TOKEN: window.localStorage.getItem('accessToken'),

  /** Directories and folders. */
  IMAGES_DIR: '/images',
  LOGOS_DIR: '/images/logos',
  ICONS_DIR: '/images/icons',
};

export default BaseDirectories;
