import { useEffect } from 'react';
import './App.scss';
import './components/Constant/colors.css';
import MainRoutes from './MainRoutes';
import { Button, Flex, Input, Text, useToast } from '@chakra-ui/react';
// import "./App.css";
import Navbar from './Header/Navbar';
import { useState } from 'react';
import axios from 'axios';
import GetStarted from './Getstarted/GetStarted';
import Question from './Question/Question';

function App() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState('');
  const [success, setSuccess] = useState(false);

  const toast = useToast();

  const sendData = () => {
    console.log('name', name);
    console.log('email', email);
    console.log('number', number);
    setName('');
    setEmail('');
    setNumber('');
    setSuccess(true);
    // toast({
    //   title: 'Successful.',
    //   description: 'Thank you for joining our wait list!',
    //   status: 'success',
    //   duration: 9000,
    //   isClosable: true,
    // });
  };

  const userFn = async () => {
    try {
      setName('');
      setEmail('');
      setNumber('');
      const request = await axios.post(
        'https://gateway.pocketfood.io/?action=subscriber',
        {
          name: name,
          email: email,
          number: number,
        },
      );
      if (request) {
        setSuccess(true);
      }
      console.log('request', request);
    } catch (err) {
      console.log('err', err);
      toast({
        title: 'Failed.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    // <div className="App">
    //   <MainRoutes />
    // </div>
    <div className="App">
      <Navbar />
      <Flex
        align="center"
        justify={'center'}
        direction={'column'}
        mt={{ base: '60px', lg: '40px' }}
      >
        <Text
          color={'#000'}
          fontSize={{ base: '20px', lg: '50px' }}
          fontWeight={700}
          letterSpacing={'-1.92px'}
        >
          We are upgrading!
        </Text>
        <Text
          color={'#F18313'}
          fontSize={{ base: '20px', lg: '50px' }}
          fontWeight={700}
          lineHeight={{ base: '30px', lg: '50px' }}
          letterSpacing={'-1.92px'}
        >
          Be the first to know <br /> when we are back.
        </Text>
      </Flex>
      <Flex
        direction={'column'}
        justify={'center'}
        align={'center'}
        w={{ base: '100%', lg: '348px' }}
        px={{ base: '20px', lg: 0 }}
        maxW={'700px'}
        mx="auto"
        mt="30px"
      >
        <Input
          w="100%"
          type="text"
          border="1px solid #AA9F93"
          placeholder="Enter your name"
          h="45px"
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          _placeholder={{
            color: '#4c4c4b',
            fontsize: '14px',
            fontWeight: 400,
            letterSpacing: '0.07px',
          }}
        />
        <Input
          my="16px"
          type="email"
          placeholder="Enter your email address"
          name="email"
          h="45px"
          w="100%"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          border="1px solid #AA9F93"
          _placeholder={{
            color: '#4c4c4b',
            fontsize: '14px',
            fontWeight: 400,
            letterSpacing: '0.07px',
          }}
        />
        <Input
          type="text"
          placeholder="Enter your phone number (optional)"
          name="number"
          h="45px"
          value={number}
          onChange={(e) => setNumber(e.target.value)}
          w="100%"
          border="1px solid #AA9F93"
          _placeholder={{
            color: '#4c4c4b',
            fontsize: '14px',
            fontWeight: 400,
            letterSpacing: '0.07px',
          }}
        />
      </Flex>
      <Flex align={'center'} justify={'center'} mt="20px">
        {!success && (
          <Button
            borderRadius={'36px'}
            bgColor={'#F18313'}
            color={'#fff'}
            w="234px"
            h="56px"
            _hover={{ bgColor: 'none' }}
            onClick={userFn}
          >
            Let me know
          </Button>
        )}
        {success && (
          <Text fontSize={'14px'} fontWeight={600} color={'#269C40'}>
            Thank you for joining our wait list!
          </Text>
        )}
      </Flex>
      <GetStarted />
      <Question />
    </div>
  );
}

export default App;
